


















































import { defineComponent, reactive } from "@vue/composition-api"

import { useI18n } from "@/_i18n"
import { login, LoginResult, LoginData } from "@/_service/user"
import { useApiCall } from "@/_use/apiCall"
import { required } from "@/_validation"

import FormCard from "@/component/FormCard.vue"
import Password from "@/component/Password.vue"

export default defineComponent({
  name: "Login",

  components: { FormCard, Password },

  setup() {
    const { t } = useI18n(
      require.context("./", true, /Login\.[\w-]+\.(json|ya?ml)$/i)
    )

    const loginData = reactive<LoginData>({ username: "", password: "" })

    const {
      isLoading,
      onSubmit,
      errorMessage,
      hasErrors: isErrorVisible,
    } = useApiCall<LoginData, LoginResult>(login, t, loginData)

    const validationRules = {
      username: [[required, "error.userName.required"]],
      password: [[required, "error.password.required"]],
    }

    // current password visibility
    return {
      errorMessage,
      isErrorVisible,
      isLoading,
      loginData,
      onSubmit,
      t,
      validationRules,
    }
  },
})
