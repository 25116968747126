import { Ref, ref } from "@vue/composition-api"

/**
 * object, representing toggle (switch)
 */
export interface UseToggle {
  /**
   * the state (on/off)
   */
  isOn: Ref<boolean>
  /**
   * toggle state
   */
  toggle: () => void
}

/**
 * prepare toggle data to be used in template
 */
export function useToggle(): UseToggle {
  const isOn = ref(false)
  return {
    isOn: isOn,
    toggle() {
      isOn.value = !isOn.value
    },
  }
}
