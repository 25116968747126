<template>
  <v-text-field
    v-bind="attrs"
    v-on="listeners"
    :type="isVisible ? 'text' : 'password'"
    :append-icon="isVisible ? 'mdi-eye-off' : 'mdi-eye'"
    @click:append="toggleVisibility"
  />
</template>

<script>
import { computed, defineComponent } from "@vue/composition-api"

import { useToggle } from "@/_use/toggle"

export default defineComponent({
  name: "Password",

  setup(_, ctx) {
    const attrs = computed(() => ctx.attrs)
    const listeners = computed(() => ctx.listeners)

    const { isOn: isVisible, toggle: toggleVisibility } = useToggle()

    return {
      attrs,
      isVisible,
      listeners,
      toggleVisibility,
    }
  },
})
</script>
