




import { defineComponent } from "@vue/composition-api"
import { useI18n } from "@/_i18n"

export default defineComponent({
  name: "Error404",

  setup() {
    const { t } = useI18n(
      require.context("./", true, /Error\.[\w-]+\.(json|ya?ml)$/i),
      true
    )

    return {
      t,
    }
  },
})
